<script>
import { PhxIcon } from '@phx-styleguides/jlc';

export default {
  components: {
    PhxIcon,
  },

  props: {
    localisations: {
      type: Array,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedLocalisationId: null,
      lastDisplayedFlag: null,
    };
  },

  computed: {
    selectedLocalisation() {
      return this.localisations.find((localisation) => localisation.id === Number(this.selectedLocalisationId));
    },

    dispatcherURL() {
      const referer = window.location.pathname.replace(/^\/[a-z]{2}-[a-z]{2}/, '');
      const params = referer !== '' ? { referer } : null;

      return window.route('dispatcher', params);
    },

    countryCodes() {
      return Object.keys(this.countries);
    },

    link() {
      return this.selectedLocalisation?.url ?? this.dispatcherURL;
    },

    tracking() {
      return JSON.stringify(
        window.tracking.getCountryPropositionTracking(this.selectedLocalisation?.country_code ?? 'other')
      );
    },
  },

  mounted() {
    this.$refs.countryPropositionBanner.addEventListener(
      'countryPropositionLocalisationsVisible',
      this.handleClassChange
    );
  },

  methods: {
    handleClassChange(el) {
      this.$refs.localisations.forEach((local) => {
        if (!local.dataset.displayFlag) {
          this.$refs.flags.find((flag) => flag.dataset.code === local.dataset.code).classList.add('hidden');
        }

        if (local.classList.contains('hidden')) {
          local.remove();
        }
      });

      const local = this.$refs.localisations.find((local) => !local.classList.contains('hidden'));
      if (local) {
        this.selectedLocalisationId = local.value;
      }

      el.target.removeEventListener('countryPropositionLocalisationsVisible', this.handleClassChange);
    },

    onSelectChange(value) {
      if (value.target.value === this.$refs.dispatcherOption.value) {
        this.lastDisplayedFlag = this.$refs.flags.find((flag) => !flag.classList.contains('hidden'));
        this.lastDisplayedFlag?.classList?.add('hidden');
      } else {
        this.lastDisplayedFlag?.classList?.remove('hidden');
      }
    },
  },
};
</script>

<template>
  <div id="country-proposition-banner" ref="countryPropositionBanner" class="promotion-banner-wrapper collapse">
    <div class="promotion-banner promotion-banner--light promotion-banner--with-country-switcher">
      <div class="container promotion-banner__container">
        <div class="promotion-banner__country-switcher-text text-sm">
          {{ $t('CountryProposition.text') }}
        </div>

        <div class="promotion-banner__country-switcher-form">
          <div class="field--select__wrapper field--select__wrapper-with-icon-start">
            <span
              v-for="code in countryCodes"
              :key="code"
              ref="flags"
              :data-code="code"
              class="country-proposition-item hidden"
            >
              <phx-icon :icon="code.toLowerCase()" />
            </span>

            <select
              v-model="selectedLocalisationId"
              class="field field--select field--select__wrapper"
              @change="onSelectChange"
            >
              <option
                v-for="localisation in localisations"
                ref="localisations"
                :key="localisation['label']"
                class="country-proposition-item hidden"
                :value="localisation['id']"
                :data-code="localisation['country_code']"
                :data-display-flag="localisation['display_flag']"
              >
                {{ localisation['select_label'] }}
              </option>

              <option ref="dispatcherOption" :value="dispatcherURL">
                {{ $t('CountryProposition.choice.other') }}
              </option>
            </select>
          </div>

          <a class="btn btn--default btn--negative" :href="link" :data-tracking="tracking">
            <span class="btn__text">{{ $t('CountryProposition.continue') }}</span>
          </a>
        </div>

        <button
          type="button"
          class="btn btn--default btn--xs btn--icon-only btn--icon promotion-banner__close-btn"
          data-toggle="collapse"
          data-target="#country-proposition-banner"
          aria-expanded="true"
        >
          <span class="sr-only">{{ $t('Accessibility.modal.close') }}</span>
          <span class="btn__icon">
            <phx-icon icon="cross-16" :title="$t('Accessibility.promotionClose')" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
